import { Container } from '@algbra/ui/components/Grid/Grid'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { graphql } from 'gatsby'
import React from 'react'
import PageLayout from '../components/PageLayout/PageLayout'
import SubpageHeader from '../components/SubpageHeader/SubpageHeader'
import { getMetaTags } from '../utils/metaTags'
import { News } from '../zones/News/News'
import ZoneNewsCategoryNavigation from '../zones/NewsCategoryNavigation/NewsCategoryNavigation'
import styles from './news-category.module.scss'

export default function LayoutNewsCategory({ pageContext, data, location }) {
  const list = data.strapiGQL.newsArticles
  return (
    <PageLayout>
      <SubpageHeader
        title={pageContext.title}
        summary={pageContext.subtitle}
        metaTags={getMetaTags(pageContext)}
      />
      <ZoneNewsCategoryNavigation
        __typename="StrapiGQL_ComponentZonesNewsCategoryNavigation"
        id="001"
        title="News Category Nav"
      />
      <Container>
        <Whitespace type="md">
          <News list={list} itemClassName={styles.itemWrapper} />
        </Whitespace>
      </Container>
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query NewsByCategoryQuery($slug: String!) {
    strapiGQL {
      newsArticles(
        publicationState: LIVE
        sort: "date:desc"
        where: { newsCategory: { slug: $slug } }
      ) {
        ...StrapiGQL_NewsArticlesFragment
        newsImage {
          __typename
          id
          url
          ext
          resolved {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                formats: WEBP
                webpOptions: { quality: 100 }
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
      }
    }
  }
`

import { Container } from '@algbra/ui/components/Grid/Grid'
import Permalink from '@algbra/ui/components/Permalink/Permalink'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { map } from 'rambdax'
import { ReactNode } from 'react'
import styles from './NewsCategoryNavigation.module.scss'

export type ZoneNewsCategoryNavigationProps = {
  __typename: 'StrapiGQL_ComponentZonesNewsCategoryNavigation'
  id: string
  title: ReactNode
}

export const QUERY_NEWS_CATEGORY_NAV = graphql`
  query NewsCategoryQuery {
    strapiGQL {
      newsCategories(publicationState: LIVE) {
        id
        slug
        title
      }
    }
  }
`

export default function ZoneNewsCategoryNavigation(
  props: ZoneNewsCategoryNavigationProps
) {
  const {
    strapiGQL: { newsCategories },
  } = useStaticQuery(QUERY_NEWS_CATEGORY_NAV)
  return (
    <Container className={styles.container}>
      <nav className={styles.nav}>
        <Link to="/news" activeClassName={styles.active}>
          All News
        </Link>
        {map(
          ({ id, title, slug }) => (
            <Link activeClassName={styles.active} to={`/news/category/${slug}/`} key={id}>
              {title}
            </Link>
          ),
          newsCategories || []
        )}
      </nav>
    </Container>
  )
}
